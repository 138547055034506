<template>
  <v-card flat v-if="detalle.length > 0" color="transparent" class="detalle-text">
    <v-container fluid>
      <v-row 
        no-gutters 
        v-for="(item, i) in detalle" 
        :key="`detalle${item.valor}-${i}`"
        :justify="$vuetify.breakpoint.mobile ? 'center' : (left ? 'end' : 'start')">
        <span v-if="left" class="chivo d-block"><span class="turquesa--text">{{ item.nombre }}</span> <b>{{item.valor}}</b></span>
        <span v-if="!left" class="chivo d-block"><b>{{ item.valor }}</b> <span class="turquesa--text">{{item.nombre}}</span></span>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'DetalleBaseSection',
  props: {
    detalle: {
    type: Array,
    default: () => []
    },
    left: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style>
.detalle-text {
  font-size: .8em;
}
</style>